<template>
  <v-container fluid>
    <BackButton :handler="goToWorkshops"/>
    <v-container class="form_container" fluid no-gutters style="max-width: 95% !important;">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="bordered bg-white rounded-lg p-8">
          <div class="row">
            <div class="col-12 col-md-7 d-flex justify-center flex-column">
              <h3 class="text-2xl font-semibold" style="font-weight: 700 ; font-size: 20px; line-height: 20px">
                {{ workshop.name || "Create an Academy" }} </h3>
              <p class="mt-5" style="font-weight: 500 ; font-size: 14px; ">
                {{ workshop.description && truncateWithEllipsis(workshop.description, 250) || "Academy Description" }}
              </p>
              <p>

                {{ workshop.start_date || "Start Date Here" }}
                - {{ workshop.end_date || "End Date Here" }}

              </p>
            </div>

            <div class="col-12 col-md-5" style="">
              <div style="height: 250px; width: 100%">
                <image-uploader
                    :height="250"
                    :image_path="workshop.image_path"
                    defaultImage="workshop"
                    message-position="inside"
                    message-text="JPG and PNG images are allowed, Recommended dimensions 2180*1080 (2:1)
Maximum Size 3MB"
                    @remove="
                  () => {
                    workshop.image = null;
                  }
                "
                    @upload="
                  (data) => {
                    workshop.image = data;
                  }
                "
                ></image-uploader>
              </div>
            </div>
          </div>
        </div>


        <v-row class="mt-4" dense>
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <p class="text-lg black-text font-bold">Basic Information</p>

              <div class="d-flex gap-x-2">
                <v-switch
                    v-model="workshop.enable_sales_channels"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Enable Sales Channels"

                ></v-switch>
                <v-switch
                    v-model="workshop.allow_programs"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Enable Programs"

                ></v-switch>

                <v-switch
                    v-model="workshop.is_public"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Enable Online"

                ></v-switch>


                <v-switch
                    v-model="isArabicEnabled"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Arabic"
                ></v-switch>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-card class="rounded shadow-0 bordered">

              <v-card-text>
                <v-row dense>
                  <v-col cols="6" md="6" sm="12">
                    <v-row dense>
                      <v-col cols="12" md="12" sm="12">
                        <label>Academy Name*</label>
                        <v-text-field
                            v-model="workshop.name"
                            :rules="[(v) => !!v || 'Academy Name is required']"
                            background-color="#fff"
                            class="q-text-field shadow-0"
                            dense
                            hide-details="auto"
                            outlined
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="isArabicEnabled" cols="12" md="12" sm="12">
                        <label>Academy Name (Ar)</label>
                        <v-text-field
                            v-model="workshop.ar_name"
                            background-color="#fff"
                            class="q-text-field shadow-0"
                            dense
                            hide-details="auto"
                            outlined
                        ></v-text-field>
                      </v-col>
<!-- keep this v-if="false" here, because we have some walk-in academies, it will cause problems in the academies logic -->
                      <v-col cols="6" v-if="false">
                        <label>Type*</label>
                        <v-select
                            v-model="workshop.workshop_type_id"
                            :items="workshopTypes"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :readonly="editMode"
                            :rules="[(v) => !!v || 'Type is required']"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            dense
                            hide-details="auto"
                            item-text="name"
                            item-value="id"
                            outlined
                            required
                        ></v-select>
                      </v-col>
                      <!--                    <v-col cols="4" md="4">-->
                      <!--                      <label>Fee Type*</label>-->

                      <!--                      <v-select-->
                      <!--                          v-model="workshop.type"-->
                      <!--                          :items="[-->
                      <!--                      { slug: 'F', name: 'Free' },-->
                      <!--                      { slug: 'P', name: 'Paid' },-->
                      <!--                    ]"-->
                      <!--                          outlined-->
                      <!--                          :menu-props="{ bottom: true, offsetY: true }"-->
                      <!--                          :rules="[(v) => !!v || 'Academy Type is required']"-->
                      <!--                          item-text="name"-->
                      <!--                          item-value="slug"-->
                      <!--                          background-color="#fff"-->
                      <!--                          :readonly="editMode"-->
                      <!--                          class="q-autocomplete shadow-0"-->
                      <!--                          dense-->
                      <!--                          hide-details="auto"-->
                      <!--                      ></v-select>-->
                      <!--                    </v-col>-->
                      <v-col cols="6">
                        <label>Service*</label>

                        <v-select
                            v-model="workshop.venue_service_id"
                            :items="venueServices"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="[(v) => !!v || 'Service is required']"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            dense
                            hide-details="auto"
                            item-text="name"
                            item-value="venue_service_id"
                            outlined
                            required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <label>Start date*</label>
                        <date-picker-field
                            v-model="workshop.start_date"
                            :backFill="
                      checkBackfillPermission($modules.workshops.schedule.slug)
                    "
                            :dense="true"
                            :rules="[(v) => !!v || 'Start Date is required']"
                            background-color="#fff"
                            outlined
                            required
                            @change="workshopDateChange('start')"
                        />

                      </v-col>
                      <v-col cols="6">
                        <label>End date*</label>

                        <date-picker-field
                            v-model="workshop.end_date"
                            :dense="true"
                            :hide-details="true"
                            :minDate="workshop.start_date"
                            :rules="[(v) => !!v || 'End date is required']"
                            class-name="q-text-field shadow-0"
                            label=""
                            @change="workshopDateChange('end')"
                        >
                        </date-picker-field>
                      </v-col>
                      <v-col cols="6" v-if="workshop.enable_sales_channels">
                        <label>Sales Channels</label>
                        <v-select
                            v-model="workshop.sales_channels"
                            :items="salesChannels"
                            :rules="[(v) => !!v || 'Duration is required']"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            dense
                            item-text="label"
                            item-value="value"
                            hide-details="auto"
                            outlined
                            required
                            multiple
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <label>Description</label>
                    <v-textarea
                        v-model="workshop.description"
                        :rows="isArabicEnabled ? 4 :6"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        name="description"
                        outlined
                    >


                    </v-textarea>
                    <label v-if="isArabicEnabled">Description Arabic</label>
                    <v-textarea v-if="isArabicEnabled"
                                v-model="workshop.ar_description"
                                background-color="#fff"
                                class="q-text-field shadow-0"
                                dense
                                hide-details="auto"
                                name="ar_description"
                                outlined
                                rows="4"
                    >

                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <v-expansion-panels
            v-for="(program, wp) in workshop.programs"
            :key="wp" accordion class="mt-4" flat
        >
          <template
              class=""
          >
            <v-expansion-panel :key="`as_${wp}`" class="bordered">
              <v-expansion-panel-header hide-actions>
                <template v-slot:default="{ open }">
                  <div class="d-flex align-center gap-x-2">
                    <MinusIcon v-if="open"/>
                    <PlusIcon v-else/>
                    <p class="align-center font-semibold text-base font-16 p-0 m-0">
                      {{ program.name ? program.name : `Program ${wp + 1}` }} </p>
                  </div>

                </template>


              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-4">
                <v-tooltip v-if="workshop.programs.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        absolute
                        fab
                        icon
                        right
                        style="margin-top: -8px"
                        top
                        v-bind="attrs"
                        x-small
                        @click="deleteWorkshopProgram(wp)"
                        v-on="on"
                    >
                      <DeleteIcon/>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <div class="container--fluid">
                  <v-row dense>
                    <v-col cols="6" md="4" sm="4" v-if="workshop.allow_programs">
                      <label>Program Name*</label>

                      <v-text-field
                          v-model="program.name"
                          :rules="[(v) => !!v || 'Program Name is required']"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="workshop.allow_programs && isArabicEnabled" cols="6" md="4" sm="4">
                      <label>Program Name (Ar)</label>

                      <v-text-field
                          v-model="program.ar_name"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2" sm="4">
                      <label>Capacity*</label>

                      <v-text-field
                          v-model="program.capacity"
                          :rules="[
                          (v) => !!v || 'Capacity is required',
                          (v) => !isNaN(v) || 'Quantity must be Number',
                        ]"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <label>Duration*</label>
                      <v-select
                          v-model="program.duration"
                          :items="[
                          { text: '15 min', value: 15 },
                          { text: '30 min', value: 30 },
                          { text: '45 min', value: 45 },
                          { text: '1 hr', value: 60 },
                          { text: '1 hr 30 min', value: 90 },
                          { text: '2 hr', value: 120 },
                          { text: '2 hr 30 min', value: 150 },
                          { text: '3 hr', value: 180 },
                          { text: '3 hr 30 min', value: 210 },
                          { text: '4 hr', value: 240 },
                        ]"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="[(v) => !!v || 'Duration is required']"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                          hint="Session duration (default 1 hr)"
                          outlined
                          required
                          @change="programDurationChange(wp)"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-expansion-panels
                          v-model="openTimeTabs"
                          accordion
                          class=""
                          flat multiple
                      >
                        <template
                            v-for="(date_range, dr) in program.date_ranges"
                        >
                          <v-expansion-panel :key="'date_range' + dr" class="mt-4"
                                             style="border: 1px solid #EAEAEA ;  border-radius: 0.75rem "
                          >
                            <v-tooltip v-if="dr > 0" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="program.date_ranges.length > 0"
                                    absolute
                                    color="red"
                                    dark
                                    fab
                                    icon
                                    right
                                    top
                                    v-bind="attrs"
                                    x-small
                                    @click="deletedDateRange(wp, dr)"
                                    v-on="on"
                                >
                                  <DeleteIcon/>

                                </v-btn>
                              </template>
                              Delete Date Range
                            </v-tooltip>


                            <v-expansion-panel-header class="pa-4 border-bottom" hide-actions style="padding: 0">
                              <template v-slot:default="{ open }">
                                <div class="d-flex align-center gap-x-1">
                                  <MinusIcon v-if="open"/>
                                  <PlusIcon v-else/>
                                  <p class="align-center font-semibold text-base font-16 p-0 m-0">
                                    Date Range {{ dr + 1 }}:
                                    <span
                                        v-if="
                                    date_range.start_date && date_range.end_date
                                  "
                                    >
                                  From
                                  {{ date_range.start_date | dateformat }} To
                                  {{ date_range.end_date | dateformat }}</span
                                    >
                                  </p>
                                </div>
                              </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <div class="container--fluid">
                                <v-row class="ma-4" dense>

                                  <v-col md="3">
                                    <label>Start Date*</label>
                                    <date-picker-field
                                        v-model="date_range.start_date"
                                        :backFill="
                                        checkBackfillPermission(
                                          $modules.workshops.schedule.slug
                                        )
                                      "
                                        :dense="true"
                                        :hide-details="true"
                                        :maxDate="workshop.end_date"
                                        :minDate="workshop.start_date"
                                        :rules="[
                                        (v) => {
                                          if (v) {
                                            if (
                                              date_range.start_date <
                                                workshop.start_date ||
                                              date_range.start_date >
                                                workshop.end_date
                                            ) {
                                              return 'Start date should be between the academy start and end date';
                                            }
                                            if (
                                              date_range.end_date <
                                              date_range.start_date
                                            ) {
                                              return 'End date should be greater than Start date';
                                            }
                                            if (
                                              program.date_ranges[dr - 1] &&
                                              program.date_ranges[dr - 1]
                                                .end_date === null
                                            ) {
                                              return (
                                                'Select the date range ' + dr
                                              );
                                            }
                                            if (
                                              program.date_ranges[dr - 1] &&
                                              program.date_ranges[dr - 1]
                                                .end_date !== null &&
                                              program.date_ranges[dr - 1]
                                                .end_date >=
                                                date_range.start_date
                                            ) {
                                              return (
                                                'Start Date should be greater than date range ' +
                                                dr
                                              );
                                            }
                                          }
                                          if (!v) {
                                            return 'Start date is required';
                                          }
                                          return true;
                                        },
                                      ]"
                                        class-name="q-text-field shadow-0"
                                        label=""

                                        @change="
                                        checkBookingsExistsInFacility(wp, dr)
                                      "
                                    >
                                    </date-picker-field>
                                  </v-col>
                                  <v-col md="3">
                                    <label>End Date*</label>
                                    <date-picker-field
                                        v-model="date_range.end_date"
                                        :backFill="
                                        checkBackfillPermission(
                                          $modules.workshops.schedule.slug
                                        )
                                      "
                                        :dense="true"
                                        :hide-details="true"
                                        :maxDate="workshop.end_date"
                                        :rules="[
                                        (v) => {
                                          if (v) {
                                            if (
                                              date_range.end_date <
                                              date_range.start_date
                                            ) {
                                              return 'End date should be greater than Start date';
                                            }
                                            if (
                                              program.date_ranges[dr - 1] &&
                                              program.date_ranges[dr - 1]
                                                .end_date === null
                                            ) {
                                              return (
                                                'Select the date range ' + dr
                                              );
                                            }
                                            if (
                                              program.date_ranges[dr - 1] &&
                                              program.date_ranges[dr - 1]
                                                .end_date !== null &&
                                              program.date_ranges[dr - 1]
                                                .end_date >=
                                                date_range.start_date
                                            ) {
                                              return (
                                                'End Date should be greater than date range ' +
                                                dr
                                              );
                                            }
                                          }
                                          if (!v) {
                                            return 'End date is required';
                                          }
                                          return true;
                                        },
                                      ]"
                                        class-name="q-text-field shadow-0"

                                        label=""
                                        @change="
                                        checkBookingsExistsInFacility(wp, dr)
                                      "
                                    >
                                    </date-picker-field>
                                  </v-col>


                                </v-row>

                                <v-row class="ma-4" dense>
                                  <v-col md="12">
                                    <v-expansion-panels accordion flat>
                                      <v-expansion-panel v-for="(schedule, ps) in date_range.schedules" :key="ps"
                                                         class="mt-4"
                                                         style="border: 1px solid #EAEAEA ;  border-radius: 0.75rem ">

                                        <v-expansion-panel-header class="pa-4 border-bottom" hide-actions
                                                                  style="padding: 0">
                                          <template v-slot:default="{ open }">
                                            <div class="d-flex align-center gap-x-1">
                                              <MinusIcon v-if="open"/>
                                              <PlusIcon v-else/>
                                              <p class="align-center font-semibold text-base font-16 p-0 m-0">
                                                Timing {{ ps + 1 }} </p>
                                            </div>
                                          </template>
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>
                                          <v-row class="ma-4" dense>
                                            <v-tooltip bottom>
                                              <template
                                                  v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                    v-if="program.date_ranges.length > 0"
                                                    absolute
                                                    color="red"
                                                    dark
                                                    fab
                                                    icon
                                                    right
                                                    top
                                                    v-bind="attrs"
                                                    x-small
                                                    @click="deletedSchedules(wp, dr, ps)"
                                                    v-on="on"
                                                >
                                                  <DeleteIcon/>

                                                </v-btn>
                                              </template>
                                              Delete Timing
                                            </v-tooltip>
                                            <v-col lg="4">
                                              <label>Days Applicable*</label>

                                              <v-autocomplete
                                                  v-model="schedule.weekdays"
                                                  :items="weekdays"
                                                  :rules="[
                                              (v) =>
                                                v.length > 0 ||
                                                'Days Applicable is required',
                                            ]"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  dense
                                                  hide-details="auto"
                                                  item-text="name"
                                                  item-value="bit_value"
                                                  multiple
                                                  outlined
                                                  validate-on-blur
                                                  @change="
                                              checkBookingsExistsInFacility(
                                                wp,
                                                dr,
                                                ps
                                              )
                                            "
                                              >
                                                <template
                                                    v-if="
                                                weekdays.length ==
                                                  schedule.weekdays.length
                                              "
                                                    v-slot:selection="{ index }"
                                                >
                                              <span v-if="index == 0"
                                              >All Days</span
                                              >
                                                </template>
                                                <template
                                                    v-else
                                                    v-slot:selection="{ item, index }"
                                                >
                                              <span v-if="index == 0">{{
                                                  item.name
                                                }}</span>
                                                  <span v-if="index == 1"
                                                  >,{{ item.name }}</span
                                                  >
                                                  <span v-if="index == 2"
                                                  >, ...</span
                                                  >
                                                </template>
                                                <template v-slot:prepend-item>
                                                  <v-list-item
                                                      ripple
                                                      @click="
                                                  toggle(
                                                    'schedules',
                                                    wp,
                                                    dr,
                                                    ps
                                                  )
                                                "
                                                  >
                                                    <v-list-item-action>
                                                      <v-icon
                                                          :color="
                                                      schedule.weekdays.length >
                                                      0
                                                        ? 'indigo darken-4'
                                                        : ''
                                                    "
                                                      >{{
                                                          getIcon(
                                                              "schedules",
                                                              wp,
                                                              dr,
                                                              ps
                                                          )
                                                        }}
                                                      </v-icon
                                                      >
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                      <v-list-item-title
                                                      >All
                                                      </v-list-item-title
                                                      >
                                                    </v-list-item-content>
                                                  </v-list-item>
                                                </template>
                                              </v-autocomplete>
                                            </v-col>

                                            <v-col lg="4" md="4">
                                              <label>Ground Assignment – Service*</label>
                                              <v-select
                                                  v-model="schedule.venue_service_id"
                                                  :items="venueServices"
                                                  :menu-props="{
                                              bottom: true,
                                              offsetY: true,
                                            }"
                                                  :rules="[
                                              (v) =>
                                                !!v || 'Service is required',
                                            ]"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  dense
                                                  hide-details="auto"
                                                  item-text="name"
                                                  item-value="venue_service_id"
                                                  outlined
                                                  required
                                                  @change="
                                              getFacilities($event),
                                                (schedule.is_external = false),
                                                getTimesByDuration(wp, dr, ps)
                                            "
                                              ></v-select>
                                            </v-col>
                                            <v-col lg="4" md="4">
                                              <label
                                                >{{ schedule.is_external ? 'Ground Assignment - External Location' : 'Ground Assignment – Facility' }}</label>

                                              <v-select
                                                  v-model="schedule.facility_id"
                                                  :items="
                                              getServiceFacilities(wp, dr, ps)
                                            "
                                                  :menu-props="{
                                              bottom: true,
                                              offsetY: true,
                                            }"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  clearable
                                                  dense
                                                  hide-details="auto"
                                                  item-text="name"
                                                  item-value="id"
                                                  outlined
                                                  @change="
                                              facilityChange(
                                                wp,
                                                dr,
                                                ps,
                                                'internal'
                                              )
                                            "

                                              >
                                                <template v-slot:append-item>
                                                  <v-divider></v-divider>
                                                  <v-list-item
                                                      ripple
                                                      @click="
                                                  facilityChange(
                                                    wp,
                                                    dr,
                                                    ps,
                                                    'external'
                                                  )
                                                "
                                                  >
                                                    <v-list-item-action>
                                                      <v-icon color="teal"
                                                      >mdi-open-in-new
                                                      </v-icon
                                                      >
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                      <v-list-item-title
                                                      >External
                                                      </v-list-item-title
                                                      >
                                                    </v-list-item-content>
                                                  </v-list-item>
                                                </template>

                                              </v-select>
                                            </v-col>
                                            <v-col lg="4" md="4">
                                              <label>Start Time*</label>

                                              <v-select
                                                  v-model="schedule.start_time"
                                                  :items="
                                              getServiceTimeSlot(wp, dr, ps)
                                            "
                                                  :menu-props="{
                                              bottom: true,
                                              offsetY: true,
                                            }"
                                                  :rules="[
                                              (v) =>
                                                !!v || 'Start time is required',
                                            ]"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  dense
                                                  hide-details="auto"
                                                  item-text="formatted"
                                                  item-value="time"
                                                  outlined
                                                  validate-on-blur
                                                  @change="
                                              EndTimeValidator(wp, dr, ps)
                                            "
                                              ></v-select>
                                            </v-col>
                                            <v-col lg="4" md="4">
                                              <label>End Time*</label>

                                              <v-select
                                                  v-model="schedule.end_time"
                                                  :items="
                                              getServiceTimeSlot(wp, dr, ps)
                                            "
                                                  :menu-props="{
                                              bottom: true,
                                              offsetY: true,
                                            }"
                                                  :readonly="true"
                                                  :rules="[
                                              (v) =>
                                                !!v ||
                                                'Facility is not available for this time! Change start time ',
                                            ]"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  dense
                                                  hide-details="auto"
                                                  item-text="formatted"
                                                  item-value="time"
                                                  outlined
                                                  validate-on-blur
                                              ></v-select>
                                            </v-col>
                                            <v-col lg="4" md="4">
                                              <label>Trainers*</label>

                                              <v-autocomplete
                                                  v-model="schedule.trainer_ids"
                                                  :items="trainers"
                                                  :rules="[
                                              (v) =>
                                                !!v.length ||
                                                'Trainer(s) is required',
                                            ]"
                                                  background-color="#fff"
                                                  class="q-autocomplete shadow-0"
                                                  dense
                                                  hide-details="auto"
                                                  item-text="name"
                                                  item-value="id"
                                                  multiple
                                                  outlined
                                                  validate-on-blur
                                              >
                                              </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                v-if="schedule.checkBookingsExist"
                                            >
                                              <p class="isBookingExists">
                                                {{ schedule.checkBookingsExist }}
                                              </p>
                                            </v-col>
                                            <v-col lg="12" md="12">
                                              <v-card
                                                  v-if="schedule.is_external"
                                                  flat
                                                  outlined
                                              >
                                                <v-btn
                                                    absolute
                                                    elevation="1"
                                                    fab
                                                    right
                                                    top
                                                    x-small
                                                    @click="
                                                showFullScreenMap(
                                                  schedule,
                                                  wp,
                                                  ps
                                                )
                                              "
                                                >
                                                  <v-icon>mdi-fullscreen</v-icon>
                                                </v-btn>
                                                <v-card-text>
                                                  <v-row dense>
                                                    <v-col
                                                        class="pr-4"
                                                        cols="12"
                                                        md="12"
                                                        sm="12"
                                                    >
                                                      <v-row v-if="!mapFullScreenDialoge"
                                                             dense
                                                             no-gutters
                                                      >
                                                        <v-col md="12">
                                                          <label>Location*</label>

                                                          <v-autocomplete
                                                              v-model="
                                                          schedule.location
                                                        "
                                                              :items="
                                                          schedule.locationEntries
                                                        "
                                                              :loading="
                                                          schedule.isLoading
                                                        "
                                                              :rules="[
                                                          (v) =>
                                                            !!v ||
                                                            'Location is required',
                                                        ]"
                                                              background-color="#fff"
                                                              class="q-autocomplete shadow-0"
                                                              dense
                                                              hide-details="auto"
                                                              hide-no-data
                                                              item-text="value"
                                                              item-value="value"
                                                              outlined
                                                              @change="
                                                          changeLocation(
                                                            schedule
                                                          )
                                                        "
                                                              @keyup="
                                                          changelocationText(
                                                            $event.target.value,
                                                            schedule
                                                          )
                                                        "
                                                          ></v-autocomplete>
                                                        </v-col>
                                                      </v-row>
                                                      <GmapMap
                                                          :zoom="12"
                                                          map-type-id="terrain"
                                                          style="
                                                      width: 100%;
                                                      height: 200px;
                                                    "
                                                          v-bind:center="{
                                                      lat: parseFloat(
                                                        schedule.latitude
                                                      ),
                                                      lng: parseFloat(
                                                        schedule.longitude
                                                      ),
                                                    }"
                                                          @click="
                                                      updateCoordinates(
                                                        $event,
                                                        wp,
                                                        ps
                                                      )
                                                    "
                                                      >
                                                        <GmapMarker
                                                            ref="mapRef"
                                                            :clickable="true"
                                                            :draggable="true"
                                                            :position="{
                                                        lat: parseFloat(
                                                          schedule.latitude
                                                        ),
                                                        lng: parseFloat(
                                                          schedule.longitude
                                                        ),
                                                      }"
                                                            @dragend="
                                                        updateCoordinates(
                                                          $event,
                                                          wp,
                                                          ps
                                                        )
                                                      "
                                                        />
                                                      </GmapMap>
                                                    </v-col>
                                                  </v-row>
                                                </v-card-text>
                                              </v-card>
                                            </v-col>
                                          </v-row>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                  </v-col>
                                  <v-col cols="12">
                                    <p class="text-neon pointer text-base font-semibold mt-2"
                                       @click="addTiming(wp, dr)">+Add Timing</p>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-col>

                    <v-col md="12">


                    <p class="text-neon pointer text-base font-semibold mt-2"  @click="addDateRange(wp)">+Add Date Range</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-expansion-panels
                        flat
                        hover
                        tile
                        light
                        multiple
                        accordion
                        v-model="openPriceTabs"
                    >
                      <template
                          v-for="(product, pp) in program.pricing"
                      ><v-expansion-panel
                          :key="'product' + pp" style="border: 1px solid rgb(234, 234, 234); border-radius: 0.75rem;" class="mb-4"
                      >
                        <v-expansion-panel-header style="padding: 0" hide-actions class="pa-4 border-bottom">
                          <template v-slot:default="{ open }">
                            <div class="d-flex align-center gap-x-1">
                              <MinusIcon v-if="open"/>
                              <PlusIcon v-else/>
                              <p class="align-center font-semibold text-base font-16 p-0 m-0">
                                {{ product.name? product.name: `Product ${pp + 1}` }}
                                <span class="ml-2" style="display: inline-block;">
                                <v-radio-group
                                  class="benefit_type_radio"
                                  v-model="product.package_type"
                                  row
                                >
                                  <v-radio
                                      label="Periodic Slots"
                                      :value="1"
                                      class="custom-radio"
                                      color="green"
                                      @click.stop
                                      :readonly="product.id?true:false"
                                  />
                                  <v-radio
                                      label="Session Based"
                                      :value="2"
                                      class="custom-radio"
                                      color="red"
                                      @click.stop
                                      :readonly="product.id?true:false"
                                  />
                                  <v-radio
                                      v-if="checkReadPermission($modules.workshops.programBased.slug)"
                                      label="Program Based"
                                      :value="3"
                                      class="custom-radio"
                                      color="red"
                                      @click.stop
                                      :readonly="product.id?true:false"
                                  />
                                </v-radio-group>
                                </span>
                              </p>
                            </div>
                          </template>
                        </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                      v-if="program.pricing.length > 0"
                                      absolute
                                      color="red"
                                      dark
                                      fab
                                      icon
                                      right
                                      top
                                      v-bind="attrs"
                                      x-small
                                      @click="deletedProductPricing(wp, pp)"
                                      v-on="on"
                                  >
                                    <DeleteIcon/>

                                  </v-btn>
                                </template>
                                Delete
                              </v-tooltip>
                              <v-container fluid>
                                <v-row dense>
                                  <v-col
                                      sm="4"
                                      cols="12"
                                  >
                                    <label>Product*</label>

                                    <v-text-field
                                        v-model="product.name"
                                        :rules="[
                                        (v) =>
                                          !!v || 'Product name is required',
                                      ]"
                                        background-color="#fff"
                                        class="q-text-field shadow-0"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        required
                                    ></v-text-field>
                                  </v-col>


                                  <v-col v-if="isArabicEnabled"
                                         sm="4"
                                         cols="12"
                                  >
                                    <label>Product (Ar)*</label>

                                    <v-text-field
                                        v-model="product.ar_name"
                                        background-color="#fff"
                                        class="q-text-field shadow-0"
                                        dense
                                        hide-details="auto"
                                        label="Product (Ar)*"
                                        outlined
                                        required
                                    ></v-text-field>
                                  </v-col>

                              <v-col lg="2" md="2" v-if="workshop.workshop_type_id == 1 && product.package_type == 1">
                                <label>Duration type*</label>
                                <v-select
                                    :items="durationType"
                                    item-text="name"
                                    item-value="slug"
                                    v-model="product.duration"
                                    class="q-autocomplete shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[ (v) => !!v || 'Duration is required',]"
                                    outlined
                                    :menu-props="{bottom: true, offsetY: true,}"
                                    validate-on-blur
                                    background-color="#fff"
                                    @change="durationChange(wp, pp),validateDate(product.count, wp, pp)"
                                ></v-select>
                              </v-col>

                              <v-col
                                  cols="12"
                                  sm="2"
                                  v-if="workshop.workshop_type_id == 1 && product.package_type == 1"
                              >
                                <label>Duration Count*</label>
                                <v-text-field
                                    :disabled="product.duration == 'FT'"
                                    required
                                    outlined
                                    background-color="#fff"
                                    @change="validateDate($event, wp, pp)"
                                    v-model="product.count"
                                    class="q-text-field shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        (v) => {
                                          if (product.duration == 'FT')
                                            return true;
                                          if (!v) return 'Count is required';
                                          return true;
                                        },
                                      ]"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                  lg="2"
                                  md="2"
                                  v-if="workshop.workshop_type_id == 1 && product.package_type == 1"
                              >
                                <label>Occurrence*</label>
                                <v-select
                                    :items="occurrence"
                                    item-text="name"
                                    item-value="slug"
                                    v-model="product.occurrence"
                                    class="q-autocomplete shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        (v) => !!v || 'Occurrence is required',
                                      ]"
                                    outlined
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                      }"
                                    validate-on-blur
                                    background-color="#fff"
                                ></v-select>
                              </v-col>

                              <v-col
                                  cols="12"
                                  sm="2"
                                  v-if="workshop.workshop_type_id == 1 && product.package_type == 1"
                              >
                                <label>Frequency*</label>
                                <v-text-field
                                    required
                                    outlined
                                    background-color="#fff"
                                    v-model="product.frequency"
                                    class="q-text-field shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        (v) => {
                                          if (!v)
                                            return 'Frequency is required';
                                          if (isNaN(v))
                                            return 'Quantity must be Number';
                                          if (v <= 0)
                                            return 'Frequency should greater than zero';
                                          if (v > 20)
                                            return 'Maximum frequency 20';

                                          return true;
                                        },
                                      ]"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                  cols="12"
                                  sm="2"
                                  v-if="product.package_type == 2"
                              >
                                <label>Sessions*</label>
                                <v-text-field
                                    required
                                    outlined
                                    background-color="#fff"
                                    v-model="product.sessions"
                                    class="q-text-field shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        (v) => {
                                          if (!v)
                                            return 'Session is required';
                                          if (isNaN(v))
                                            return 'Session must be Number';
                                          if (v <= 0)
                                            return 'Session should be greater than 0';
                                          if (v > 100)
                                            return 'Session should be less than 100';

                                          return true;
                                        },
                                      ]"
                                ></v-text-field>
                              </v-col>

                              <v-col md="2">
                                <label>Tax*</label>
                                <v-select
                                    @change="taxChange(product)"
                                    :disabled="workshop.type == 'F'"
                                    v-model="product.tax_type_id"
                                    :items="taxTypes"
                                    item-value="value"
                                    item-text="text"
                                    hint="Required tax"
                                    class="q-autocomplete shadow-0"
                                    dense
                                    hide-details="auto"
                                    :rules="[
                                        (v) => {
                                          if (workshop.type == 'F') return true;
                                          if (!v) return 'Tax is required';
                                          return true;
                                        },
                                      ]"
                                        background-color="#fff"

                                        outlined
                                    ></v-select>
                                  </v-col>

                              <v-col cols="12" sm="2" md="2">
                                  <label>Price (Pre Tax)*</label>
                                  <v-text-field
                                        v-model="product.pre_tax_price"
                                        :disabled="workshop.type == 'F'"
                                        :rules="priceRules"
                                        :suffix="currencyCode"
                                        background-color="#fff"
                                        class="q-text-field shadow-0"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        required
                                        @change="
                                        calculateTaxVariation(
                                          $event,
                                          product,
                                          'pre'
                                        )
                                      "
                                    ></v-text-field>
                              </v-col>
                             <v-col cols="12" sm="2" md="2">
                                <label>Price (Post Tax)*</label>

                                  <v-text-field
                                    required
                                    outlined
                                    :disabled="workshop.type == 'F'"
                                    background-color="#fff"
                                    :suffix="currencyCode"
                                    v-model="product.total_price"
                                    class="q-text-field shadow-0"
                                    dense
                                    hide-details="auto"
                                    @change="
                                        calculateTaxVariation(
                                          $event,
                                          product,
                                          'post'
                                        )
                                      "
                                    ></v-text-field>
                                  </v-col>

                              <v-col cols="4" v-if="[1].includes(product.package_type)">
                                <v-switch
                                    :false-value="0"
                                    :true-value="1"
                                    label="Allow Pro Rata calculation"
                                    v-model="product.allow_pro_rate"
                                ></v-switch>
                              </v-col>
                            </v-row>
                            <v-row v-if="isEnableDctErp" dense>
                              <v-col cols="12" sm="6" md="3">
                                <label>Project Number *</label>

                                    <v-text-field
                                        v-model="projectNumber"
                                        :readonly="projectNumber ? true : false"
                                        :rules="[
                                        (v) =>
                                          !!v || 'Project number is required',
                                      ]"
                                        background-color="#fff"
                                        class="q-text-field shadow-0"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        placeholder="Project Number"

                                        required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <label>Transaction Type*</label>

                                    <v-text-field
                                        v-model="transactionType"
                                        :readonly="transactionType ? true : false"
                                        :rules="[
                                        (v) =>
                                          !!v || 'Transaction Type is required',
                                      ]"
                                        background-color="#fff"
                                        class="q-text-field shadow-0"
                                        dense
                                        hide-details="auto"
                                        outlined

                                        placeholder="Transaction Type"
                                        required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <label>Task Name*</label>

                                    <v-select
                                        v-model="product.task_name"
                                        :items="taskNames"
                                        :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                      }"
                                        :rules="[
                                        (v) => !!v || 'Task name is required',
                                      ]"
                                        background-color="#fff"
                                        class="q-autocomplete shadow-0"

                                        dense
                                        hide-details="auto"
                                        hint="Required Task Name"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                    ></v-select>
                                    <!-- <v-text-field
                                      outlined
                                      background-color="#fff"
                                      v-model="product.task_name"
                                      hide-details="auto"
                                      label="Task Name *"
                                      placeholder="Task Name"
                                      required
                                      :rules="[
                                        (v) => !!v || 'Task name is required',
                                      ]"
                                    ></v-text-field> -->
                                  </v-col>
                                  <v-col cols="12" md="3" sm="6">
                                    <label>GL Code*</label>

                                    <v-select
                                        v-model="product.gl_code"
                                        :items="glCodes"
                                        :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                      }"
                                        :rules="[
                                        (v) => !!v || 'GL code is required',
                                      ]"
                                        background-color="#fff"
                                        class="q-autocomplete shadow-0"

                                        dense
                                        hide-details="auto"
                                        hint="Required GL Code"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                    ></v-select>
                                    <!-- <v-text-field
                                      outlined
                                      background-color="#fff"
                                      v-model="product.gl_code"
                                      hide-details="auto"
                                      label="Gl Code *"
                                      placeholder="Gl Code"
                                      required
                                      :rules="[
                                        (v) => !!v || 'Gl Code is required',
                                      ]"
                                    ></v-text-field> -->
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-col>

                    <v-col cols="12">
                      <p class="text-neon pointer text-base font-semibold mt-2" @click="addPricing(wp)">+Add Pricing</p>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>


        <v-row class="mt-2" v-if="workshop.allow_programs" dense>
          <v-col>
            <v-btn
                class="shadow-0"
                color="teal-color"
                dark
                @click="addProgram"
            >
              + Add Program
            </v-btn>
          </v-col>
        </v-row>


        <v-expansion-panels accordion class="mt-4" flat>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header hide-actions style="">
              <template v-slot:default="{ open }">
                <div class="d-flex align-center gap-x-2">
                  <MinusIcon v-if="open"/>
                  <PlusIcon v-else/>
                  <p class="align-center font-semibold text-base font-16 p-0 m-0">
                    Documents</p>
                </div>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="pa-4">
              <v-row v-for="(document, k) in workshop.documents" :key="'d_' + k"
                     dense>
                <v-col cols="12" md="4" sm="4">
                  <label>Name</label>
                  <v-text-field
                      v-model="document.name"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4">
                  <label>Type</label>

                  <v-select
                      v-model="document.document_type_id"
                      :items="documentTypes"
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="Type"
                      outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                  <label
                    >{{ editMode && document.original_file_name ? document.original_file_name : 'Select File' }}</label>

                  <v-file-input

                      v-model="document.file"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      prepend-icon
                      prepend-inner-icon="mdi-paperclip"
                  >

                  </v-file-input>
                </v-col>
                <v-col cols="1">
                  <div class="d-flex text-right">
                    <v-btn
                        class="shadow-0 mt-6"
                        icon
                        @click="deleteDocuments(k)"
                    >
                      <DeleteIcon/>

                    </v-btn>
                  </div>

                </v-col>

              </v-row>
              <v-row dense>
                <v-col>
                  <p class=" text-base text-neon  font-semibold pointer" style="cursor: pointer;" @click="addDocuments">
                    +Add Documents</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>


        <v-row dense>
          <GeneralMembership
              :existingMembershipData="workshop.benefit_workshop"
              @updateForm="updateFormWithMembership"
          />
        </v-row>
        <v-row class="mt-2" dense>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 black--text" color="darken-1"
              right
              style="background-color: rgba(17, 42, 70, 0.1)"
              text
              @click="updateWorkshop"
          >Save As Draft
          </v-btn>
          <v-btn
              class="ma-2 white--text blue-color"
              color="darken-1"
              right
              text
              @click="publishWorkshop"
          >Publish
          </v-btn>

        </v-row>

        <v-dialog v-model="locationDialoge" persistent scrollable width="40%">
          <v-card>
            <v-card-title class="headline">Select Location</v-card-title>
            <v-card-text class="pa-5">
              <v-row de>
                Please select a location from the list. If you don't see correct
                location name in the list below try changing location on map by
                dragging the marker or clicking on the preffered location.
              </v-row>
              <v-row>
                <v-select
                    v-model="workshop.location"
                    :items="mapLocations"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="formatted_address"
                    item-value="formatted_address"
                    outlined
                ></v-select>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="blue-color white--text" text @click="closeLocation"
              >Close
              </v-btn
              >
              <v-btn class="teal-color white--text" text @click="dragMapChange"
              >Done
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="mapFullScreenDialoge" scrollable width="80%">
          <v-card>
            <v-card-text class="pa-4">
              <v-row v-if="mapFullScreenDialoge" no-gutters>
                <v-col md="12">
                  <v-autocomplete
                      v-model="tempSchedule.location"
                      :items="tempSchedule.locationEntries"
                      :loading="tempSchedule.isLoading"
                      background-color="#fff"
                      hide-no-data
                      item-text="value"
                      item-value="value"
                      label="Location"
                      outlined
                      @change="changeLocation(tempSchedule)"
                      @keyup="changelocationText($event.target.value, tempSchedule)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <GmapMap
                  :zoom="12"
                  map-type-id="terrain"
                  style="width: 100%; height: 600px"
                  v-bind:center="{
                lat: parseFloat(tempSchedule.latitude),
                lng: parseFloat(tempSchedule.longitude),
              }"
                  @click="updateCoordinates($event, tmpPindex, tmpSindex)"
              >
                <GmapMarker
                    ref="mapRef"
                    :clickable="true"
                    :draggable="true"
                    :position="{
                  lat: parseFloat(tempSchedule.latitude),
                  lng: parseFloat(tempSchedule.longitude),
                }"
                    @dragend="updateCoordinates($event, tmpPindex, tmpSindex)"
                />
              </GmapMap>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-form>
      <confirm-model
          v-bind="confirmModel"
          @close="confirmModel.id = null"
          @confirm="confirmActions"
      ></confirm-model>
    </v-container>
  </v-container>
</template>
<script>
import {GetSuggestions, placeDetails} from "@/utils/placesUtils";
import constants from "../../utils/constants";
import moment from "moment";
import GeneralMembership from "@/components/Membership/GeneralMembership.vue";
import BackButton from "@/components/Common/BackButton.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import PlusIcon from "@/assets/images/misc/plus.svg";
import MinusIcon from "@/assets/images/misc/minus-icon.svg";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import DatePickerField from "@/components/Fields/DatePickerField.vue";

export default {
  components: {DatePickerField, ImageUploader, BackButton, GeneralMembership, PlusIcon, MinusIcon, DeleteIcon},
  data() {
    return {
      isArabicEnabled: false,
      isPanelOpened: false,
      membershipForm: [],
      openTimeTabs: [0],
      openPriceTabs: [0],
      openProgramTabs: [0],
      addStageDialog: false,
      locationDialoge: false,
      mapFullScreenDialoge: false,
      mapLocations: [],
      locationHistory: {},
      valid: true,
      loc: {
        latitude: 24.46436049078158,
        longitude: 54.37532545660189,
      },
      workshop: {
        workshop_type_id: 1,
        allow_programs: 0,
        is_public: 0,
        name: null,
        type: null,
        description: null,
        venue_service_id: null,
        status_id: 11,
        enable_sales_channels:0,
        sales_channels:['b2c'],
        start_date: null,
        end_date: null,
        facility_id: null,
        documents: [
          {
            name: null,
            document_type_id: null,
            file: null,
          },
        ],
        programs: [
          {
            name: null,
            duration: null,
            capacity: null,
            date_ranges: [
              {
                start_date: null,
                end_date: null,
                schedules: [
                  {
                    venue_service_id: null,
                    facility_id: null,
                    weekdays: [],
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                    latitude: 24.45342255,
                    longitude: 54.35851069,
                    heatmap: null,
                    is_external: false,
                    autocompleteLocationModel: null,
                    location: null,
                    locationEntries: [],
                    isLoading: false,
                  },
                ],
              },
            ],
            pricing: [
              {
                name: null,
                duration: "FT",
                count: 1,
                occurrence: "W",
                frequency: 1,
                price: null,
                tax_type: null,
                allow_pro_rate: 1,
                package_type:1,
              },
            ],
          },
        ],
        benefit_workshop: [],
        deleted_date_ranges: [],
        deleted_schedules: [],
        deleted_documents: [],
        deleted_programs: [],
        deleted_pricing: [],
      },
      facilities: [],
      timeSlot: [],
      levels: [],
      editMode: false,
      timings: constants.TIMINGS,
      isLoading: false,
      autocompleteLocationModel: null,
      locationSearchText: null,
      locationEntries: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      workshopTypes: [],
      trainers: [],
      tempWorkshopForLocation: null,
      programIndex: null,
      scheduleIndex: null,
      dateRangeIndex: null,
      occurrence: [
        //Don;'t remove: TODO:
        // { slug: "Y", name: "Yearly" },
        // { slug: "M", name: "Monthly" },
        // Commented becouse can't handle yearly & montly without date selection
        {slug: "W", name: "Weekly"},
        // { slug: "D", name: "Daily" },
      ],
      durationType: [
        {slug: "FT", name: "Full term"},
        // { slug: "M", name: "Month" },
        // { slug: "W", name: "Week" },
        // { slug: "D", name: "Day" },
      ],
      tempSchedule: {},
      tmpPindex: null,
      tmpSindex: null,
      toggleStatus: "",
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
      projectNumber: null,
      transactionType: null,
      isEnableDctErp: false,
    };
  },
  watch:{
    'workshop.name'(val) {
      if(!this.workshop.allow_programs && this.workshop.programs.length > 0){
        this.workshop.programs[0].name = val;
      }
    },
  },
  computed: {
    salesChannels(){
      let channels = [
        {
          label:"B2C",
          value:'b2c'
        }
      ];
      if (this.checkReadPermission(this.$modules.b2b.partner.slug)){
        channels = [
          ...channels,
          {
            label:"B2B",
            value:'b2b'
          },
          {
            label:"B2E",
            value:'b2e'
          },
          {
            label:"B2G",
            value:'b2g'
          }
        ];
      }
      if (this.checkReadPermission(this.$modules.kiosk.workshop.slug)){
        channels = [
          ...channels,
          {
            label:"Kiosk",
            value:'kiosk'
          }
        ];
      }
      return channels;
    },
    priceRules() {
      return [
        (v) => {
          if (this.workshop.type === 'F') return true;
          if (v === '' || v === null || v === undefined || isNaN(Number(v)))
            return 'Price must be a number';
          if (Number(v) < 0) return 'Price should be zero or greater';
          return true;
        },
      ];
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    // isEnableDctErp() {
    //   if (
    //     this.$store.getters.userVenues &&
    //     this.$store.getters.userVenues.length > 0
    //   ) {
    //     return this.$store.getters.userVenues[0].enable_dct_erp;
    //   }
    //   return 0;
    // },
  },
  created() {
    if (typeof this.$route.params.data != "undefined") {
      this.workshop.id = parseInt(atob(this.$route.params.data));
    }
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (!this.$store.getters.getWeekdays.data.length) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber = this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType = this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes").then((res) => {
        if (res.status == 200 && this.workshop.id) {
          this.getWorkshopDetails();
        }else{
          this.getDefaultMembershipConfig();
        }
      });
    } else if (this.workshop.id) {
      this.getWorkshopDetails();
    }else{
      this.getDefaultMembershipConfig();
    }
    this.getWorkshopTypes();
    this.getTrainers();
  },
  methods: {
    getDefaultMembershipConfig(){
      this.$http
          .get(`venues/workshops/configuration`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let configuration = response.data.data;
              if(configuration.benefit_workshop){
                this.workshop.benefit_workshop = configuration.benefit_workshop.map((ele) => {
                  delete ele.id;
                  return ele;
                });
              }
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    pannelTransitionChanged() {
      this.isPanelOpened = !this.isPanelOpened
    },
    updateFormWithMembership(data) {
      this.membershipForm = [];
      this.membershipForm = data;
      this.$forceUpdate();
    },
    changelocationText(newVal, schedule) {
      var _vue = schedule;
      var _vue2 = this;
      if (newVal == null || newVal.length == 0 || schedule.location == newVal) {
        return;
      }
      schedule.locationEntries = [];
      GetSuggestions(newVal)
          .then(function (res) {
            _vue.isLoading = false;
            _vue.locationEntries = res;
            _vue2.$forceUpdate();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showFullScreenMap(schedule, wp, sp) {
      // console.log(schedule);
      this.mapFullScreenDialoge = true;
      this.tempSchedule.location = schedule.location;
      this.tempSchedule.locationEntries = [
        {value: schedule.location, id: "0"},
      ];
      this.tempSchedule = schedule;
      this.tmpPindex = wp;
      this.tmpSindex = sp;
    },
    programDurationChange(programIndex) {
      if (this.workshop.programs[programIndex].length != 0) {
        this.workshop.programs[programIndex].date_ranges.forEach((dr) => {
          dr.schedules.forEach((schedule) => {
            schedule.is_external = false;
            schedule.facility_id = null;
            schedule.start_time = null;
            schedule.end_time = null;
          });
        });
        this.workshop.programs[programIndex].date_ranges.forEach((dr) => {
          this.openTimeTabs = dr.schedules.map((k, i) => i);
        });
      }
    },
    durationChange(programIndex, pricingIndex) {
      if (
          this.workshop.programs[programIndex].pricing[pricingIndex].duration ==
          "FT"
      ) {
        this.workshop.programs[programIndex].pricing[pricingIndex].count = 1;
      }
      if (
          this.workshop.programs[programIndex].pricing[pricingIndex].count &&
          this.workshop.programs[programIndex].pricing[pricingIndex].duration ==
          "M"
      ) {
        this.workshop.programs[programIndex].pricing[pricingIndex].count = null;
      }
    },

    validateDate(event, programIndex, pricingIndex) {
      if (
          this.workshop.programs[programIndex].pricing[pricingIndex].duration ==
          "M"
      ) {
        const start_date = moment(this.workshop.start_date);
        const end_date = moment(this.workshop.end_date);
        const month = end_date.diff(start_date, "month");
        if (month < event) {
          this.workshop.programs[programIndex].pricing[
              pricingIndex
              ].count = null;
          this.showError("Product duration is greater than workshop duration");
        }
      }
      if (
          this.workshop.programs[programIndex].pricing[pricingIndex].duration ==
          "W"
      ) {
        const start_date = moment(this.workshop.start_date);
        const end_date = moment(this.workshop.end_date);
        const week = end_date.diff(start_date, "week");
        if (week < event) {
          this.workshop.programs[programIndex].pricing[
              pricingIndex
              ].count = null;
          this.showError("Product duration is greater than workshop duration");
        }
      }
      if (
          this.workshop.programs[programIndex].pricing[pricingIndex].duration ==
          "D"
      ) {
        const start_date = moment(this.workshop.start_date);
        const end_date = moment(this.workshop.end_date);
        const days = end_date.diff(start_date, "days");

        if (days < event) {
          this.workshop.programs[programIndex].pricing[
              pricingIndex
              ].count = null;
          this.showError("Product duration is greater than workshop duration");
        }
      }
    },

    changeLocation(schedule) {
      // this.workshop.location = schedule.location;
      if (schedule.location) {
        let placeId = schedule.locationEntries.find(
            (val) => val.value == schedule.location
        ).id;
        placeDetails(placeId)
            .then((data) => {
              var lat = data[0].geometry.location.lat();
              var lng = data[0].geometry.location.lng();
              this.workshop.latitude = lat;
              this.workshop.longitude = lng;
              schedule.latitude = lat;
              schedule.longitude = lng;
              //schedule.autocompleteLocationModel = data[0].formatted_address;
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },

    dragMapChange() {
      this.tempWorkshopForLocation.locationEntries = [
        {value: this.workshop.location, id: "0"},
      ];
      this.tempWorkshopForLocation.autocompleteLocationModel = this.workshop.location;
      this.tempWorkshopForLocation.locationSearchText = this.workshop.location;
      this.locationDialoge = false;

      this.tempWorkshopForLocation.location = this.workshop.location;
    },

    addDocuments() {
      this.workshop.documents.push({
        name: "",
        document_type_id: null,
        file: null,
      });
      this.$forceUpdate();
    },

    deleteDocuments(index) {
      if (
          this.workshop.documents[index].name == "" &&
          this.workshop.documents[index].type == ""
      ) {
        this.workshop.documents.splice(index, 1);
        if (this.workshop.documents.length == 0) {
          this.workshop.documents = [{}];
        }
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you wpnt to delete this document?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "document",
        };
      }
    },

    deleteWorkshopProgram(index) {
      let data = this.workshop.programs[index];

      if (data.id == null || this.toggleStatus == "completed") {
        this.workshop.programs.splice(index, 1);
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this workshop program?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "program",
        };
      }
    },
    deletedDateRange(programIndex, drIndex) {
      let data = this.workshop.programs[programIndex].date_ranges[drIndex];
      this.programIndex = programIndex;
      this.dateRangeIndex = drIndex;
      if (data.id == null || this.toggleStatus == "completed") {
        this.workshop.programs[programIndex].date_ranges.splice(drIndex, 1);
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: data.id,
          title: "Do you want to delete this date range?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "date_range",
        };
      }
    },
    deletedSchedules(programIndex, drIndex, scheduleIndex) {
      let data = this.workshop.programs[programIndex].date_ranges[drIndex]
          .schedules[scheduleIndex];
      this.programIndex = programIndex;
      this.dateRangeIndex = drIndex;
      this.scheduleIndex = scheduleIndex;
      if (data.id == null || this.toggleStatus == "completed") {
        this.workshop.programs[programIndex].date_ranges[
            drIndex
            ].schedules.splice(scheduleIndex, 1);
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: data.id,
          title: "Do you want to delete this time slot?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "schedule",
        };
      }
    },

    deletedProductPricing(programIndex, pricingIndex) {
      let data = this.workshop.programs[programIndex].pricing[pricingIndex];
      this.programIndex = programIndex;
      this.scheduleIndex = pricingIndex;
      if (data.id == null || this.toggleStatus == "completed") {
        this.workshop.programs[programIndex].pricing.splice(pricingIndex, 1);
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: data.id,
          title: "Do you want to delete this product?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "product",
        };
      }
    },

    confirmActions(data) {
      if (data.type == "document") {
        if (this.workshop.documents[data.id].id != null)
          this.workshop.deleted_documents.push(
              this.workshop.documents[data.id].id
          );
        this.workshop.documents.splice(data.id, 1);
        if (this.workshop.documents.length == 0) {
          this.workshop.documents = [{}];
        }
      } else if (data.type == "program") {
        let adata = this.workshop.programs[data.id];
        if (adata.id != null) {
          this.workshop.deleted_programs.push(adata.id);
        }
        this.workshop.programs.splice(data.id, 1);
      } else if (data.type == "schedule") {
        if (data.id != null) {
          this.workshop.deleted_schedules.push(data.id);
        }
        this.workshop.programs[this.programIndex].date_ranges[
            this.dateRangeIndex
            ].schedules.splice(this.scheduleIndex, 1);
      } else if (data.type == "product") {
        if (data.id != null) {
          this.workshop.deleted_pricing.push(data.id);
        }
        this.workshop.programs[this.programIndex].pricing.splice(
            this.scheduleIndex,
            1
        );
      } else if (data.type == "date_range") {
        // console.log("data", data);
        // console.log("dr index", this.dateRangeIndex);
        if (data.id != null) {
          this.workshop.deleted_date_ranges.push(data.id);
          // this.workshop.deleted_schedules.push(data.id);
        }
        this.workshop.programs[this.programIndex].date_ranges.splice(
            this.dateRangeIndex,
            1
        );
      }

      this.programIndex = null;
      this.scheduleIndex = null;
      this.dateRangeIndex = null;

      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    updateWorkshop(e) {
      e.preventDefault();
      this.workshop.status_id = 11;
      this.addOrEditWorkshop();
    },
    publishWorkshop(e) {
      e.preventDefault();
      this.workshop.status_id = 1;
      this.addOrEditWorkshop();
    },
    /* eslint-disable */
    addOrEditWorkshop() {
      if(this.workshop.status_id != 11){
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }
      if (this.venueServices.length == 0) {
        this.showError(
            "No Facilities found. Please add facilities to add academy."
        );
        return;
      }
      let validateData = true;
      if (this.workshop.programs) {
        this.workshop.programs.forEach((program) => {
          if (program.date_ranges && program.date_ranges.length > 0) {
            program.date_ranges.forEach((element) => {
              if (element.schedules.length === 0) {
                validateData = false;
                this.showError(
                    `Please select schedules for date range: From ${this.$options.filters.dateformat(
                        element.start_date
                    )} To ${this.$options.filters.dateformat(element.end_date)}`
                );
                return;
              }
            });
          } else {
            validateData = false;
            this.showError(`Please select at least one date range`);
            return;
          }
        });
      }
      if (!validateData) {
        return;
      }
      this.showLoader("Saving, Please be patient, it will take some time");
      let formData = new FormData();
      for (let key in this.workshop) {
        if (key == "programs") {
          this.workshop[key].forEach((program, index) => {
            for (let key_pro in program) {
              if (key_pro == "pricing") {
                program[key_pro].forEach((pricing, pr_ind) => {
                  for (let key_pro_price in pricing) {
                    if (
                        key_pro_price == "price" &&
                        pricing[key_pro_price] == null
                    ) {
                      pricing[key_pro_price] = 0;
                    }
                    if (pricing[key_pro_price] != null) {
                      formData.append(
                          `programs[${index}][${key_pro}][${pr_ind}][${key_pro_price}]`,
                          pricing[key_pro_price]
                      );

                      if (this.isEnableDctErp && key_pro_price == "task_name") {
                        formData.append(
                            `programs[${index}][${key_pro}][${pr_ind}][project_no]`,
                            this.projectNumber
                        );
                        formData.append(
                            `programs[${index}][${key_pro}][${pr_ind}][transaction_type]`,
                            this.transactionType
                        );
                      }
                    }
                  }
                });
              } else if (key_pro == "date_ranges") {
                program[key_pro].forEach((date_range, dr) => {
                  // console.log("date range", dr);
                  // console.log("date range", date_range);
                  for (let key_pro_dr in date_range) {
                    if (
                        date_range[key_pro_dr] !== null &&
                        key_pro_dr != "schedules"
                    ) {
                      formData.append(
                          `programs[${index}][${key_pro}][${dr}][${key_pro_dr}]`,
                          date_range[key_pro_dr]
                      );
                    } else if (
                        date_range[key_pro_dr] !== null &&
                        key_pro_dr == "schedules"
                    ) {
                      date_range[key_pro_dr].forEach((schedule, pr_sche) => {
                        for (let key_pro_sche in schedule) {
                          if (schedule[key_pro_sche] !== null) {
                            if (key_pro_sche == "weekdays") {
                              formData.append(
                                  `programs[${index}][${key_pro}][${dr}][${key_pro_dr}][${pr_sche}][${key_pro_sche}]`,
                                  JSON.stringify(schedule[key_pro_sche])
                              );
                            } else if (key_pro_sche == "trainer_ids") {
                              schedule[key_pro_sche].forEach(
                                  (trainer_ids, train_ind) => {
                                    formData.append(
                                        `programs[${index}][${key_pro}][${dr}][${key_pro_dr}][${pr_sche}][${key_pro_sche}][${train_ind}]`,
                                        trainer_ids
                                    );
                                  }
                              );
                            } else {
                              formData.append(
                                  `programs[${index}][${key_pro}][${dr}][${key_pro_dr}][${pr_sche}][${key_pro_sche}]`,
                                  schedule[key_pro_sche]
                              );
                            }
                          }
                        }
                      });
                    }
                  }
                });
              } else {
                if (program[key_pro] != null) {
                  formData.append(
                      `programs[${index}][${key_pro}]`,
                      program[key_pro]
                  );
                }
              }
            }
          });
        } else if (key == "documents") {
          this.workshop[key].forEach((document, doc_ind) => {
            if (document.name && document.document_type_id) {
              if (document.id) {
                formData.append(`documents[${doc_ind}][id]`, document.id);
              }
              if (document.file) {
                formData.append(`documents[${doc_ind}][file]`, document.file);
              }
              formData.append(`documents[${doc_ind}][name]`, document.name);
              formData.append(
                  `documents[${doc_ind}][document_type_id]`,
                  document.document_type_id
              );
            }
          });
        } else if (
            key == "deleted_programs" ||
            key == "deleted_date_ranges" ||
            key == "deleted_schedules" ||
            key == "deleted_documents" ||
            key == "deleted_pricing"
        ) {
          formData.append(key, JSON.stringify(this.workshop[key]));
        } else {
          if (key == "is_public") {
            formData.append(key, this.workshop[key]);
          }
          if (key == "allow_programs") {
            formData.append(key, this.workshop[key]);
          } else {
            if (this.workshop[key]) formData.append(key, this.workshop[key]);
          }
        }
      }


      if (this.membershipForm.data && this.membershipForm.data.length > 0) {

        formData.append(`membership_benefits_enable`, this.membershipForm.enable);

        this.membershipForm.data.forEach((ele, index) => {
          formData.append(`membership_benefits[${index}][membership_id]`, ele.membership_id);
          formData.append(`membership_benefits[${index}][membership_package_id]`, ele.membership_package_id);
          formData.append(`membership_benefits[${index}][membership_ids]`, ele.membership_ids);
          formData.append(`membership_benefits[${index}][membership_package_ids]`, ele.membership_package_ids);
          formData.append(`membership_benefits[${index}][benefit_type]`, ele.benefit_type);
          formData.append(`membership_benefits[${index}][benefit]`, ele.benefit);
          formData.append(`membership_benefits[${index}][benefit_id]`, ele.benefit_id);
          formData.append(`membership_benefits[${index}][id]`, ele.id);
        })

        this.membershipForm.deleted.forEach((ele, index) => {
          formData.append(`deleted_membership_benefits[${index}][membership_id]`, ele.membership_id);
          formData.append(`deleted_membership_benefits[${index}][membership_package_id]`, ele.membership_package_id);
          formData.append(`deleted_membership_benefits[${index}][benefit_type]`, ele.benefit_type);
          formData.append(`deleted_membership_benefits[${index}][benefit]`, ele.benefit);
          formData.append(`deleted_membership_benefits[${index}][benefit_id]`, ele.benefit_id);
          formData.append(`deleted_membership_benefits[${index}][id]`, ele.id);
        })
      }
      // for (var pair of formData.entries()) {
      // }
      this.$http
          .post(`venues/workshops`, formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.goToWorkshops();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    updateCoordinates(e, programIndex, scheduleIndex) {
      this.tempWorkshopForLocation = this.workshop.programs[
          programIndex
          ].schedules[scheduleIndex];
      this.locationHistory.lat = this.workshop.latitude;
      this.locationHistory.lng = this.workshop.longitude;

      let lat = e.latLng.lat();
      let lng = e.latLng.lng();

      this.tempWorkshopForLocation.latitude = lat;
      this.tempWorkshopForLocation.longitude = lng;

      let geocoder = new google.maps.Geocoder();
      var locationss = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      let that = this;
      geocoder.geocode({location: locationss}, function (results, status) {
        if (status === "OK") {
          if (results.length > 0) {
            that.mapLocations = results;
            that.workshop.location = results[0].formatted_address;
            that.locationDialoge = true;
          }
        }
      });
      this.workshop.latitude = lat;
      this.workshop.longitude = lng;
    },

    closeLocation() {
      this.locationDialoge = false;
      this.workshop.latitude = this.locationHistory.lat;
      this.workshop.longitude = this.locationHistory.lng;
      this.workshop.location = null;
    },

    async getFacilities(venueServiceId) {
      if (this.facilities[venueServiceId] && venueServiceId > 0) return;
      this.facilities[venueServiceId] = [];
      await this.$http
          .get(`venues/facilities/short?venue_service_id=${venueServiceId}`)
          .then((response) => {
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data.length != 0
            ) {
              const data = response.data.data;
              let select = [];
              let type = data[0].type;
              select.push({header: type.toUpperCase()});
              select.push({divider: true});
              data.forEach((facility) => {
                if (facility.type != type) {
                  select.push({divider: true});
                  select.push({header: facility.type.toUpperCase()});
                  select.push({divider: true});
                  type = facility.type;
                }
                select.push(facility);
              });
              this.facilities[venueServiceId] = select;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getServiceFacilities(main_ind, dr_index, s_index) {
      let venue_service_id = null;
      venue_service_id = this.workshop.programs[main_ind].date_ranges[dr_index]
          .schedules[s_index].venue_service_id;
      if (
          venue_service_id !== null &&
          this.facilities[venue_service_id] != null
      ) {
        return this.facilities[venue_service_id];
      }
      return [];
    },

    getLevels(venueServiceId) {
      this.$http
          .get(
              `venues/general/color-codes/workshop?venue_service_id=${venueServiceId}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.levels = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getTimesByDuration(programIndex, drIndex, scheduleIndex) {
      let venueServiceId = this.workshop.programs[programIndex].date_ranges[
          drIndex
          ].schedules[scheduleIndex].venue_service_id;
      let facilityId = this.workshop.programs[programIndex].date_ranges[drIndex]
          .schedules[scheduleIndex].facility_id;

      let duration = 0;
      if (
          this.workshop.programs[programIndex] &&
          this.workshop.programs[programIndex].duration
      ) {
        duration = this.workshop.programs[programIndex].duration;
      }

      if (duration) {
        if (
            this.timeSlot[venueServiceId + "." + duration + "." + facilityId] &&
            venueServiceId > 0
        )
          return;
        this.timeSlot[venueServiceId + "." + duration + "." + facilityId] = [];
        let url = "";
        if (facilityId) {
          url = `&facility_id=${facilityId}`;
        }
        this.$http
            .get(
                `venues/workshops/time-by-duration?duration=${duration}&venue_service_id=${venueServiceId}` +
                url
            )
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.timeSlot[
                venueServiceId + "." + duration + "." + facilityId
                    ] = response.data.data;
                this.$forceUpdate();
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      } else {
        this.showError("Please select duration!");
      }
    },

    getServiceTimeSlot(main_ind, dr_index, s_index) {
      let duration = this.workshop.programs[main_ind].duration;
      let venueServiceId = this.workshop.programs[main_ind].date_ranges[
          dr_index
          ].schedules[s_index].venue_service_id;
      let facilityId = this.workshop.programs[main_ind].date_ranges[dr_index]
          .schedules[s_index].facility_id;
      if (
          this.timeSlot[venueServiceId + "." + duration + "." + facilityId] !=
          null
      ) {
        return this.timeSlot[
        venueServiceId + "." + duration + "." + facilityId
            ];
      }
      return [];
    },

    EndTimeValidator(main_ind, dr_index, s_index) {
      const start_time = moment(
          this.workshop.programs[main_ind].date_ranges[dr_index].schedules[
              s_index
              ].start_time,
          "HH:mm:ss"
      );
      this.workshop.programs[main_ind].date_ranges[dr_index].schedules[
          s_index
          ].end_time = moment(start_time)
          .add(this.workshop.programs[main_ind].duration, "minutes")
          .format("HH:mm:ss");

      const timeSlot = this.getServiceTimeSlot(main_ind, dr_index, s_index);
      const solotIndex = timeSlot.findIndex(
          (x) =>
              x.time ==
              this.workshop.programs[main_ind].date_ranges[dr_index].schedules[
                  s_index
                  ].end_time
      );

      if (solotIndex == -1) {
        this.showError("Facility not available this time duration");
        this.workshop.programs[main_ind].date_ranges[dr_index].schedules[
            index
            ].end_time = null;
      }

      this.checkBookingsExistsInFacility(main_ind, dr_index, s_index);
    },

    getWorkshopTypes() {
      this.$http
          .get(`venues/workshops/types`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.workshopTypes = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getTrainers() {
      this.$http
          .get(`venues/trainers/short`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.trainers = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getWorkshopDetails() {
      this.showLoader("Loading");
      this.$http
          .get(`venues/workshops/${this.workshop.id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.editMode = true;
              let data = response.data.data;
              let channels = [];
              if (data.enable_b2c){
                channels.push("b2c")
              }
              if (data.enable_b2b){
                channels.push("b2b")
              }
              if (data.enable_b2e){
                channels.push("b2e")
              }
              if (data.enable_b2g){
                channels.push("b2g")
              }
              if (data.enable_kiosk){
                channels.push("kiosk")
              }
              this.workshop = {
                id: data.status_id != 12 ? data.id : null,
                name: data.name,
                ar_name: data.ar_name,
                workshop_type_id: data.workshop_type_id,
                type: data.type,
                venue_service_id: data.venue_service_id,
                start_date: data.status_id != 12 ? data.start_date : null,
                end_date: data.status_id != 12 ? data.end_date : null,
                description: data.description,
                ar_description: data.ar_description,
                image_path: data.image_path,
                programs: data.programs,
                is_public: data.is_public,
                allow_programs: data.allow_programs,
                benefit_workshop: data.benefit_workshop,
                sales_channels: channels,
                enable_sales_channels: data.enable_sales_channels
              };

              if(data.ar_name || data.ar_description){
                this.isArabicEnabled = 1;
              }

              this.toggleStatus = data.status_id == 12 ? "completed" : "";
              this.workshop.programs.forEach((program, programIndex) => {
                if (data.status_id == 12) {
                  delete program.id;
                  delete program.workshop_id;
                }
                program.date_ranges.forEach((date_range, drIndex) => {
                  if (data.status_id == 12) {
                    delete date_range.id;
                    delete date_range.workshop_program_id;
                  }
                  date_range.schedules.forEach((schedules, scheduleIndex) => {
                    if (data.status_id == 12) {
                      delete schedules.id;
                      delete schedules.workshop_program_id;
                      delete schedules.wp_date_range_id;
                    }
                    setTimeout(() => {
                      this.getFacilities(schedules.venue_service_id);
                    });
                    setTimeout(() => {
                      this.getTimesByDuration(
                          programIndex,
                          drIndex,
                          scheduleIndex
                      );
                    });
                    if (schedules.facility_id == null && (schedules.latitude || schedules.longitude)) {
                      schedules.is_external = true;
                      schedules.location = schedules.location;
                      this.locationEntries = [
                        {value: schedules.location, id: "0"},
                      ];
                      schedules.locationEntries = [
                        {value: schedules.location, id: "0"},
                      ];
                      this.$forceUpdate();
                    }

                    schedules.trainer_ids = schedules.trainer.map(
                        (item) => item.trainer_id
                    );
                  });
                });

                program.pricing.forEach((pricing) => {
                  if (data.status_id == 12) {
                    delete pricing.id;
                    delete pricing.workshop_program_id;
                    pricing.duration = "FT";
                    pricing.occurrence = "W";
                  }
                  if (pricing.price) {
                    pricing.pre_tax_price = pricing.price;
                    pricing.total_price = pricing.total_price;
                  }else{
                    pricing.pre_tax_price = 0;
                  }
                });
              });

              if (data.documents.length == 0) {
                this.workshop.documents = [{}];
              } else {
                this.workshop.documents = data.documents.map((item) => {
                  return {
                    id: item.id,
                    document_type_id: item.document_type_id,
                    file_path: item.file_path,
                    original_file_name: item.original_file_name,
                    name: item.name,
                  };
                });
              }

              this.workshop.deleted_date_ranges = [];
              this.workshop.deleted_documents = [];
              this.workshop.deleted_schedules = [];
              this.workshop.deleted_programs = [];
              this.workshop.deleted_pricing = [];

              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    goToWorkshops() {
      this.$router.push({name: "Workshops"}, () => {
      });
    },

    facilityChange(programIndex, drIndex, scheduleIndex, type) {
      if (type == "external") {
        this.workshop.programs[programIndex].date_ranges[drIndex].schedules[
            scheduleIndex
            ].is_external = true;
        this.workshop.programs[programIndex].date_ranges[drIndex].schedules[
            scheduleIndex
            ].facility_id = null;

        this.workshop.programs[programIndex].date_ranges[drIndex].schedules[
            scheduleIndex
            ].latitude = 24.45342255;
        this.workshop.programs[programIndex].date_ranges[drIndex].schedules[
            scheduleIndex
            ].longitude = 54.35851069;
      } else {
        this.workshop.programs[programIndex].date_ranges[drIndex].schedules[
            scheduleIndex
            ].is_external = false;
      }
      this.$forceUpdate();
      this.getTimesByDuration(programIndex, drIndex, scheduleIndex);
    },

    addAvailability() {
      this.workshop.programs.push({
        start_time: null,
        end_time: null,
        weekdays: [],
      });
      this.$forceUpdate();
    },

    addFacility() {
      this.workshop.schedules.push({
        venue_service_id: null,
        facility_id: null,
        weekdays: [],
      });
      this.$forceUpdate();
    },

    duplicateFacility(index) {
      let data = this.workshop.schedules[index];
      if (data.id != null) delete data.id;
      this.workshop.schedules.splice(
          index,
          0,
          JSON.parse(JSON.stringify(data))
      );
    },

    getIcon(field, pIndex, drIndex, sIndex) {
      let icon = "mdi-checkbox-blank-outline";
      if (
          this.workshop.programs[pIndex].date_ranges[drIndex].schedules[sIndex]
              .weekdays.length == this.weekdays.length
      ) {
        icon = "mdi-close-box";
      }
      if (
          this.workshop.programs[pIndex].date_ranges[drIndex].schedules[sIndex]
              .weekdays.length > 0 &&
          this.workshop.programs[pIndex].date_ranges[drIndex].schedules[sIndex]
              .weekdays.length != this.weekdays.length
      )
        icon = "mdi-minus-box";
      return icon;
    },

    toggle(field, pIndex, drIndex, sIndex) {
      this.$nextTick(() => {
        if (
            this.workshop.programs[pIndex].date_ranges[drIndex][field][sIndex]
                .weekdays.length == this.weekdays.length
        ) {
          this.workshop.programs[pIndex].date_ranges[drIndex][field][
              sIndex
              ].weekdays = [];
        } else {
          this.workshop.programs[pIndex].date_ranges[drIndex][field][
              sIndex
              ].weekdays = this.weekdays.map((item) => {
            return item.bit_value;
          });
        }
      });
      this.$forceUpdate();
    },

    calculateTaxVariation(amount, product, type) {
      let taxTypeId = product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        product.price = priceData.price;
        product.pre_tax_price = priceData.price.toFixed(2);
        product.total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },

    taxChange(product) {
      if (product.price) {
        this.calculateTaxVariation(product.price, product, "pre");
      } else if (product.total_price) {
        this.calculateTaxVariation(product.total_price, product, "post");
      }
    },

    facilityRule(type) {
      const rules = [];
      let rule = null;
      if (!type) {
        rule = (v) => !!v || "Facility is required";
        rules.push(rule);
      }
      return rules;
    },

    workshopDateChange(type) {
      if (type == "start") {
        this.workshop.programs.forEach((programs) => {
          programs.date_ranges.forEach((dr) => {
            if (
                dr.start_date < this.workshop.start_date ||
                dr.start_date > this.workshop.end_date
            ) {
              dr.start_date = null;
              dr.schedules.forEach((schedule) => {
                schedule.start_date = null;
              });
            }
            if (
                dr.end_date > this.workshop.end_date ||
                dr.end_date < this.workshop.start_date
            ) {
              dr.end_date = null;
              dr.schedules.forEach((schedule) => {
                schedule.end_date = null;
              });
            }
          });
        });
      }
      if (type == "end") {
        this.workshop.programs.forEach((programs) => {
          programs.date_ranges.forEach((dr) => {
            if (
                dr.end_date > this.workshop.end_date ||
                dr.end_date < this.workshop.start_date
            ) {
              dr.end_date = null;
              dr.schedules.forEach((schedule) => {
                schedule.end_date = null;
              });
            }
            if (
                dr.start_date < this.workshop.start_date ||
                dr.start_date > this.workshop.end_date
            ) {
              dr.start_date = null;
              dr.schedules.forEach((schedule) => {
                schedule.start_date = null;
              });
            }
            //this.workshop.end_date;
          });
        });
      }
    },

    checkBookingsExistsInFacility(main_ind, dr_index, s_index = null) {
      var date_range = this.workshop.programs[main_ind].date_ranges[dr_index];
      if (
          date_range.start_date == "" ||
          date_range.start_date == null ||
          date_range.end_date == "" ||
          date_range.end_date == null
      ) {
        return;
      }
      if (s_index !== null) {
        var sc = this.workshop.programs[main_ind].date_ranges[dr_index]
            .schedules[s_index];
        if (
            sc.end_time &&
            date_range.start_date &&
            sc.start_time &&
            sc.facility_id
        ) {
          let schedule = "";
          if (this.toggleStatus == "completed") {
            schedule = {
              id: null,
              workshop_program_id: null,
              start_date: date_range.start_date,
              end_date: date_range.end_date,
              start_time: sc.start_time,
              end_time: sc.end_time,
              facility_id: sc.facility_id,
              weekdays: sc.weekdays,
            };
          } else {
            schedule = {
              id: sc.id ? sc.id : null,
              workshop_program_id: sc.workshop_program_id
                  ? sc.workshop_program_id
                  : null,
              start_date: date_range.start_date,
              end_date: date_range.end_date,
              start_time: sc.start_time,
              end_time: sc.end_time,
              facility_id: sc.facility_id,
              weekdays: sc.weekdays,
            };
          }

          this.$http
              .post(`venues/workshops/check-booking-exists`, schedule)
              .then((response) => {
                if (response.status == 200 && response.data.status) {
                  if (response.data.data) {
                    this.workshop.programs[main_ind].date_ranges[
                        dr_index
                        ].schedules[s_index].checkBookingsExist = response.data.data;
                  } else {
                    this.workshop.programs[main_ind].date_ranges[
                        dr_index
                        ].schedules[s_index].checkBookingsExist = null;
                  }
                  this.$forceUpdate();
                  this.hideLoader();
                }
              })
              .catch((error) => {
                this.errorChecker(error);
              });
        }
      } else {
        date_range.schedules.forEach((sc, s_index) => {
          if (
              sc.end_time &&
              date_range.start_date &&
              sc.start_time &&
              sc.facility_id
          ) {
            let schedule = "";
            if (this.toggleStatus == "completed") {
              schedule = {
                id: null,
                workshop_program_id: null,
                start_date: date_range.start_date,
                end_date: date_range.end_date,
                start_time: sc.start_time,
                end_time: sc.end_time,
                facility_id: sc.facility_id,
                weekdays: sc.weekdays,
              };
            } else {
              schedule = {
                id: sc.id ? sc.id : null,
                workshop_program_id: sc.workshop_program_id
                    ? sc.workshop_program_id
                    : null,
                start_date: date_range.start_date,
                end_date: date_range.end_date,
                start_time: sc.start_time,
                end_time: sc.end_time,
                facility_id: sc.facility_id,
                weekdays: sc.weekdays,
              };
            }
            this.$http
                .post(`venues/workshops/check-booking-exists`, schedule)
                .then((response) => {
                  if (response.status == 200 && response.data.status) {
                    if (response.data.data) {
                      this.workshop.programs[main_ind].date_ranges[
                          dr_index
                          ].schedules[s_index].checkBookingsExist =
                          response.data.data;
                    } else {
                      this.workshop.programs[main_ind].date_ranges[
                          dr_index
                          ].schedules[s_index].checkBookingsExist = null;
                    }
                    this.$forceUpdate();
                    this.hideLoader();
                  }
                })
                .catch((error) => {
                  this.errorChecker(error);
                });
          }
        });
      }
    },

    addProgram() {
      this.openProgramTabs.push(this.workshop.programs.length);
      this.workshop.programs.push({
        name: null,
        duration: null,
        capacity: null,
        date_ranges: [
          {
            start_date: null,
            end_date: null,
            schedules: [
              {
                // venue_service_id: null,
                // facility_id: null,
                // weekdays: [],
                // start_time: null,
                // end_time: null,
                // latitude: 24.45342255,
                // longitude: 54.35851069,
                // heatmap: null,
                // is_external: false,

                venue_service_id: null,
                facility_id: null,
                weekdays: [],
                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null,
                latitude: 24.45342255,
                longitude: 54.35851069,
                heatmap: null,
                is_external: false,
                autocompleteLocationModel: null,
                location: null,
                locationEntries: [],
                isLoading: false,
              },
            ],
          },
        ],

        pricing: [
          {
            name: null,
            duration: "FT",
            count: 1,
            occurrence: "W",
            frequency: 1,
            price: null,
            tax_type: null,
            allow_pro_rate: 1,
            package_type:1,
          },
        ],
      });
    },

    addPricing(index) {
      this.openPriceTabs.push(this.workshop.programs[index].pricing.length);
      this.workshop.programs[index].pricing.push({
        name: null,
        duration: "FT",
        count: 1,
        occurrence: "W",
        frequency: 1,
        price: null,
        tax_type: null,
        allow_pro_rate: 1,
        package_type:1,
      });
    },
    addDateRange(pIndex) {
      this.openTimeTabs.push(this.workshop.programs[pIndex].date_ranges.length);
      this.workshop.programs[pIndex].date_ranges.push({
        start_date: null,
        end_date: null,
        schedules: [
          {
            venue_service_id: null,
            facility_id: null,
            weekdays: [],
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            latitude: 24.45342255,
            longitude: 54.35851069,
            heatmap: null,
            is_external: false,
            autocompleteLocationModel: null,
            location: null,
            locationEntries: [],
            isLoading: false,
          },
        ],
      });
    },
    addTiming(pIndex, drIndex) {
      this.openTimeTabs.push(
          this.workshop.programs[pIndex].date_ranges[drIndex].schedules.length
      );
      this.workshop.programs[pIndex].date_ranges[drIndex].schedules.push({
        venue_service_id: null,
        facility_id: null,
        weekdays: [],
        start_time: null,
        end_time: null,
        start_date: this.workshop.start_date ? this.workshop.start_date : null,
        end_date: this.workshop.end_date ? this.workshop.end_date : null,
        latitude: 24.46436049078158,
        longitude: 54.37532545660189,
        heatmap: null,
        location: null,
        autocompleteLocationModel: null,
        locationEntries: [],
        isLoading: false,
      });
    },

    truncateWithEllipsis(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
      } else {
        return str;
      }
    }

  },
};
</script>

<style scoped>
.add_ground_containers {
  width: -webkit-fill-available;
}

.imageInput {
  padding-top: 0;
}

.add_btn {
  margin-top: -20px;
}

.isBookingExists {
  color: #a52a2a;
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: "Inter", sans-serif !important;
  text-align: center;
}

.isBookingExistsBorder {
  border: 2px solid #a52a2a;
  border-radius: 4px;
}

.v-expansion-panel-header--active {
  background-color: #E9F1F5;
}
</style>
